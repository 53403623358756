// The resource provider interface.
let resourceProvider = {
  getDatabases(model) {
    return []
  },
  getSchemas(model, schema) {
    return []
  },
  getTables(model, schema) {
    return []
  },
  getColumns(model, tableParts) {
    return []
  },
  getFunctions(model, schema) {
    return []
  },
  getLocations(model, schema) {
    return []
  },
  getStorage(model, schema) {
    return []
  },
  getFormats(model, schema) {
    return []
  }
}

// The recording of the current completion request.
let currentCompletions = null

export function setResourceProvider(_) {
  resourceProvider = _
}

// Helper hook for completions.
const completionProviders = new Map()

export function registerCompletionComponent(key, component) {
  completionProviders.set(key, component)
}

export function unregisterCompletionComponent(key) {
  completionProviders.delete(key)
}

export async function provideCompletionItems(model, position, _context) {
  // We will not pile on here; wait for prior request before proceeding.
  if (currentCompletions) {
    await currentCompletions
  }

  // Add resource provider to context.
  const context = Object.assign({ resourceProvider }, _context)

  // Dispatch to all completion providers, returning aggregate of all that make a suggestion.
  currentCompletions = Promise.all(Array.from(completionProviders.values()).map(completionProvider =>
    Promise.resolve(completionProvider.provideCompletionItems(model, position, context))))

  // Turn the completions into items.
  try {
    const completionItems = await currentCompletions
    const result = completionItems.reduce((result, items) => result.concat(...(items || [])), [])
    if (result.length === 0) {
      false && console.warn('Empty result providing editing assistance')
    }
    return result
  } catch (e) {
    console.error('Error occurred while providing editing assistance')
    console.error(e)
    return []
  } finally {
    currentCompletions = null
  }
}

// Helper for using the keybinding service
let keybindingService
const keybindingComponents = new Map()

export function registerKeybindingComponent(component) {
  keybindingComponents.set(component, component)
}

export function unregisterKeybindingComponent(component) {
  keybindingComponents.delete(component)
}

export function setKeybindingService(_) {
  keybindingService = _

  // Any queued components waiting on keybindings should re-render here; only needed once per component.
  Array.from(keybindingComponents.values()).forEach(v => v.computeKeyLabel())
  keybindingComponents.clear()
}

export function lookupKeybinding(command) {
  if (keybindingService) {
    const binding = keybindingService.lookupKeybinding(command)
    return binding && binding.getLabel()
  } else {
    return null
  }
}
