<!--
  - What?  Simple port of https://github.com/egoist/vue-monaco
  - Specifically?   https://raw.githubusercontent.com/egoist/vue-monaco/master/src/MonacoEditor.js
  - Why?
  -   * fixup how monaco is imported and remove the hooky dance the component did to
  -     gain access to the code (ie. import).  Essentially the mounted() method was removed
  -     that did this work for legacy reasons (I guess).
  -   * change "this.monaco" to just "monaco" (import)
  -   * remove nano-assign dependency
  -   * remove @deprecated support methods
  -->

<script>
import { h } from 'vue'

import monaco, { setKeybindingService } from './monaco'

export const defaultOptions = {
  quickSuggestions: true
}

export default {
  props: {
    original: String,
    modelValue: {
      type: String,
      required: false
    },
    theme: {
      type: String,
      default: 'vs'
    },
    language: String,
    options: {
      type: Object,
      default: defaultOptions
    },
    diffEditor: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: { /* Like disabled, except no css/opacity change */
      type: Boolean,
      default: false
    }
  },

  emits: ['update:modelValue', 'editorWillMount', 'editorDidMount'],

  watch: {
    options: {
      deep: true,
      handler(options) {
        if (this.editor) {
          const editor = this.getModifiedEditor()
          editor.updateOptions(options)
        }
      }
    },

    modelValue(newValue) {
      if (this.editor && !!newValue) {
        const editor = this.getModifiedEditor()
        if (newValue !== editor.getValue()) {
          editor.setValue(newValue)
        }
      }
    },

    original(newValue) {
      if (this.editor && this.diffEditor) {
        const editor = this.getOriginalEditor()
        if (newValue !== editor.getValue()) {
          editor.setValue(newValue)
        }
      }
    },

    language(newVal) {
      if (this.editor) {
        const editor = this.getModifiedEditor()
        monaco.editor.setModelLanguage(editor.getModel(), newVal)
      }
    },

    theme(newVal) {
      if (this.editor) {
        monaco.editor.setTheme(newVal)
      }
    },

    disabled(newVal) {
      if (this.editor) {
        this.editor.updateOptions({ readOnly: newVal })
      }
    },

    readOnly(newVal) {
      if (this.editor) {
        this.editor.updateOptions({ readOnly: newVal })
      }
    }
  },

  mounted() {
    if (this.$productionDataPrivate && this.$productionDataPrivate['data-private'] !== undefined) {
      this.$el.setAttribute('data-private', '')
    }
    this.$nextTick(() => {
      this.initMonaco()
    })
  },

  beforeUnmount() {
    this.editor && this.editor.dispose()
  },

  methods: {
    initMonaco() {
      this.$emit('editorWillMount', monaco)

      const options = Object.assign(
        {
          value: this.modelValue,
          theme: this.theme,
          language: this.language
        },
        this.options,
        (this.disabled || this.readOnly) ? { readOnly: true } : {}
      )

      if (this.diffEditor) {
        this.editor = monaco.editor.createDiffEditor(this.$el, options)
        const originalModel = monaco.editor.createModel(
          this.original,
          this.language
        )
        const modifiedModel = monaco.editor.createModel(
          this.modelValue,
          this.language
        )
        this.editor.setModel({
          original: originalModel,
          modified: modifiedModel
        })
      } else {
        this.editor = monaco.editor.create(this.$el, options)
      }

      // @event `change`
      const editor = this.getModifiedEditor()
      editor.onDidChangeModelContent((event) => {
        const value = editor.getValue()
        if (this.modelValue !== value) {
          this.$emit('update:modelValue', value, event)
        }
      })

      // Register the keybindings service.
      setKeybindingService(editor._standaloneKeybindingService)

      this.$emit('editorDidMount', this.editor)
    },

    getEditor() {
      return this.editor
    },

    getModifiedEditor() {
      return this.diffEditor ? this.editor.getModifiedEditor() : this.editor
    },

    getOriginalEditor() {
      return this.diffEditor ? this.editor.getOriginalEditor() : this.editor
    },

    focus() {
      this.editor.focus()
    }
  },

  render() {
    return h('div', { class: 'yb-editor' })
  }
}
</script>

<style scoped lang="postcss">
.yb-editor[disabled] :deep(.lines-content) {
  @apply opacity-50;
}

.yb-editor {
  @apply select-auto;
}

.yb-editor :deep(.monaco-editor),
.yb-editor :deep(.monaco-editor) .margin,
.yb-editor :deep(.monaco-editor-background) {
  @apply dark:bg-yb-gray-alt-dark;

}
</style>
