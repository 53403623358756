/* eslint-disable new-cap */

// Import the languages and language workers we want.
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker'
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker'
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker'

// Import monaco
import * as monaco from 'monaco-editor'

// Do ybsql language setup.
import register from './lang/ybsql'

// Do web worker setup.
self.MonacoEnvironment = {
  getWorker(_, label) {
    if (label === 'json') {
      return new jsonWorker()
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker()
    }
    return new editorWorker()
  }
}

monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
  noSemanticValidation: false,
  noSyntaxValidation: false
})

// compiler options
monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
  noLib: true,
  target: monaco.languages.typescript.ScriptTarget.ES5,
  allowNonTsExtensions: true
})

// Export monaco for outside use.
export default monaco

// Export our api.
export * from './api'

// Setup ybsql.
register(monaco)
