import { provideCompletionItems, registerCompletionComponent } from '../../api'
import { conf, language } from './lang'
import hueCompletionItemProvider from './hue'

// Register a new language
// import * as monaco from 'monaco-editor';

export async function provideCompletionItemsFromModel(model, position, monaco, syntax) {
  // Extract common context.
  const textUntilPosition = model.getValueInRange({ startLineNumber: 1, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column })
  const word = model.getWordUntilPosition(position)
  const currentWord = model.getWordAtPosition(position)
  const range = {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn
  }
  const textAfterPosition = model.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: word.endColumn,
    endLineNumber: Number.MAX_VALUE,
    endColumn: Number.MAX_VALUE
  })

  const suggestions = await provideCompletionItems(
    model,
    position,
    {
      range,
      word,
      textUntilPosition,
      textAfterPosition,
      monaco,
      currentWord: currentWord?.word,
      syntax
    }
  )
  return { suggestions: (suggestions || []) }
}

let registered
export default function register(monaco) {
  // Check if ybsql is registered previously.
  monaco.languages.getLanguages().forEach((language) => {
    registered = registered || (language.id === 'ybsql')
  })

  // Only do this once.
  if (registered) {
    return
  }
  registered = true

  // Register the language.
  monaco.languages.register({ id: 'ybsql' })

  // Setup the language configuration.
  monaco.languages.setLanguageConfiguration('ybsql', conf)

  // Setup the language tokens.
  monaco.languages.setMonarchTokensProvider('ybsql', language)

  // Setup the completion provider.
  monaco.languages.registerCompletionItemProvider('ybsql', {
    triggerCharacters: ['.', ',', '('],
    provideCompletionItems: (model, position) =>
      provideCompletionItemsFromModel(model, position, monaco)
  })

  // Our static proposals.
  registerCompletionComponent('hue', hueCompletionItemProvider)
}
