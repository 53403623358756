import sqlAutocompleteParser from 'parse/sql/yellowbrick/yellowbrickAutocompleteParser'
import { functions } from '@/util'

const DEBUG = false

const aggregateFunctions = [
  'avg',
  'count',
  'group_concat',
  'listagg',
  'max',
  'median',
  'min',
  'percentile_cont',
  'percentile_disc',
  'stddev_samp',
  'stddev_pop',
  'string_agg',
  'sum',
  'var_samp',
  'var_pop'
]
const aggregateFunctionSet = new Set(aggregateFunctions)

const CURSOR = '\u2020\''
const PARTIAL_CURSOR = '\u2021\''

export async function provideCompletionItems(model, position, context) {
  const result = []
  const { range, monaco, textUntilPosition, textAfterPosition, syntax } = context

  // Ask for suggestions.
  DEBUG && console.log('textUntilPosition [' + textUntilPosition + ']')
  DEBUG && console.log('textAfterPosition [' + textAfterPosition + ']')
  DEBUG && console.log('syntax [' + syntax + ']')
  let parsedSuggestions
  if (syntax) {
    let sql = String(textUntilPosition + textAfterPosition)
    if (!sql.trim().endsWith(';')) {
      sql += ';'
    }
    parsedSuggestions = sqlAutocompleteParser.parseSql(sql, ' ')
  } else {
    parsedSuggestions = sqlAutocompleteParser.parseSql(textUntilPosition, textAfterPosition)
  }
  DEBUG && console.log('parsedSuggestions', JSON.stringify(parsedSuggestions, null, 2))

  const kinds = {
    keyword: monaco.languages.CompletionItemKind.Keyword,
    table: monaco.languages.CompletionItemKind.Constructor,
    view: monaco.languages.CompletionItemKind.Function,
    column: monaco.languages.CompletionItemKind.Field,
    schema: monaco.languages.CompletionItemKind.Module,
    database: monaco.languages.CompletionItemKind.Value,
    default: monaco.languages.CompletionItemKind.File,
    aggFunction: monaco.languages.CompletionItemKind.Enum,
    function: monaco.languages.CompletionItemKind.EnumMember,
    procedure: monaco.languages.CompletionItemKind.EnumMember,
    location: monaco.languages.CompletionItemKind.Interface,
    storage: monaco.languages.CompletionItemKind.Class,
    format: monaco.languages.CompletionItemKind.Struct
  }
  const kindOf = type => kinds[type] || kinds.default
  const nonEmpty = (completionType, list) => {
    if (!!list && list.length > 0) {
      return list
    } else {
      return [
        {
          label: '',
          kind: kindOf(completionType),
          documentation: null,
          insertText: '',
          range,
          detail: completionType
        }
      ]
    }
  }

  // Suggest keywords.
  if (parsedSuggestions.suggestKeywords) {
    result.push(...parsedSuggestions.suggestKeywords.map((suggestion) => {
      let insertText = suggestion.value
      if (insertText.match(/[a-z0-9]$/i)) {
        insertText += ' '
      }
      return {
        label: suggestion.value,
        kind: kindOf('keyword'),
        documentation: null, // "TODO",
        insertText,
        range
      }
    }))
  } else if (!parsedSuggestions.suggestTables && !!parsedSuggestions.errors && !!parsedSuggestions.locations) {
    // We have no keywords, no table suggestions and errors.  Let's see if we have a table completion to do (ie. a database.schema.<tab>)
    const table = parsedSuggestions.locations.find(item => item.type === 'table' &&
      item.location?.last_line === context.range?.endLineNumber &&
      item.location?.last_column === context.range?.endColumn - 1)
    if (table) {
      parsedSuggestions.suggestTables = table
      parsedSuggestions.errors = []
    }
  }

  // Suggest tables.
  if (parsedSuggestions.suggestTables) {
    let prefix = ''; let suffix = ''
    if (parsedSuggestions.suggestTables.prependQuestionMark) {
      prefix += '* '
    }
    if (parsedSuggestions.suggestTables.prependFrom) {
      prefix += 'FROM '
    }
    if (parsedSuggestions.suggestTables.appendDot) {
      suffix += '.'
    }
    result.push(...nonEmpty('table', (await Promise.resolve(context.resourceProvider.getTables(model, parsedSuggestions.suggestTables?.identifierChain?.map(c => c.name).join('.')))).map((tableOrSchema) => {
      let insertText = prefix + tableOrSchema.name + suffix
      if (tableOrSchema.type === 'schema' && !suffix) {
        insertText += '.'
      }
      return {
        label: tableOrSchema.name,
        kind: kindOf(tableOrSchema.type),
        documentation: null, // "TODO",
        insertText,
        range,
        detail: tableOrSchema.type
      }
    })))
  }

  // Suggest schemas.
  if (parsedSuggestions.suggestSchemas) {
    const prefix = ''; let suffix = ''
    if (parsedSuggestions.suggestSchemas.appendDot) {
      suffix += '.'
    }
    result.push(...nonEmpty('schema', (await Promise.resolve(context.resourceProvider.getSchemas(model, parsedSuggestions.suggestSchemas?.identifierChain?.map(c => c.name).join('.')))).map((schema) => {
      return {
        label: schema.name,
        kind: kindOf(schema.type),
        documentation: null, // "TODO",
        insertText: prefix + schema.name + suffix,
        range,
        detail: schema.type
      }
    })))
  }

  // Suggest columns.
  if (parsedSuggestions.suggestColumns) {
    const columnPromises = parsedSuggestions.suggestColumns.tables
      .map(table => table.identifierChain)
      .map(async (identifierChain) => {
        if (identifierChain.length === 1 && !!identifierChain[0].subQuery) {
          const target = identifierChain[0].subQuery

          // Lookup the subquery.
          const subQuery = parsedSuggestions.subQueries
            .find(subQuery => subQuery.alias === target)
          if (!!subQuery && Array.isArray(subQuery.columns)) {
            return Promise.all(subQuery.columns.map((col) => {
              if (col.alias) {
                return Promise.resolve([{ name: col.alias }])
              } else if (col.type === 'COLREF' && !!col.identifierChain && Array.isArray(col.identifierChain) && col.identifierChain.length > 0) {
                return Promise.resolve([col.identifierChain[col.identifierChain.length - 1]])
              } else if (col.tables) {
                return Promise.all(col.tables.map(table => Promise.resolve(context.resourceProvider.getColumns(model, table.identifierChain.map(c => c.name)))))
              } else {
                return Promise.resolve([])
              }
            }))
          }
        }
        return Promise.resolve(context.resourceProvider.getColumns(model, identifierChain.map(c => c.name)))
      })

    const columns = functions.flattenDeep(await Promise.all(columnPromises))
      .filter(col => !!col)
    result.push(...nonEmpty('column', columns.map((column) => {
      return {
        label: column.name,
        kind: kindOf('column'),
        documentation: null, // "TODO",
        insertText: column.name,
        range,
        detail: 'column'
      }
    })))
  }

  // Suggest databases.
  if (parsedSuggestions.suggestDatabases) {
    let prefix = ''; let suffix = ''
    if (parsedSuggestions.suggestDatabases.prependQuestionMark) {
      prefix += '* '
    }
    if (parsedSuggestions.suggestDatabases.prependFrom) {
      prefix += 'FROM '
    }
    if (parsedSuggestions.suggestDatabases.appendDot) {
      suffix += '.'
    }
    result.push(...nonEmpty('database', (await Promise.resolve(context.resourceProvider.getDatabases(model))).map((database) => {
      return {
        label: database.name,
        kind: kindOf('database'),
        documentation: null, // "TODO",
        insertText: prefix + database.name + suffix,
        range,
        detail: 'database'
      }
    })))
  }

  // Suggest functions.
  if (parsedSuggestions.suggestFunctions) {
    result.push(...nonEmpty('function', (await Promise.resolve(context.resourceProvider.getFunctions(model, parsedSuggestions.suggestFunctions.identifierChain?.map(c => c.name))))
      .filter(func => !aggregateFunctionSet.has(String(func.name).toLowerCase()))
      .map((func) => {
        return {
          label: func.name,
          kind: kindOf('function'),
          documentation: null, // "TODO",
          insertText: func.name,
          range,
          detail: 'function'
        }
      })))
  }

  // Suggest locations.
  if (parsedSuggestions.suggestExternalLocations) {
    result.push(...nonEmpty('location', (await Promise.resolve(context.resourceProvider.getLocations(model, parsedSuggestions.suggestExternalLocations.identifierChain?.map(c => c.name))))
      .map((location) => {
        return {
          label: location.label,
          kind: kindOf('location'),
          documentation: null, // "TODO",
          insertText: location.label,
          range,
          detail: 'location'
        }
      })))
  }

  // Suggest storage.
  if (parsedSuggestions.suggestExternalStorage) {
    result.push(...nonEmpty('storage', (await Promise.resolve(context.resourceProvider.getStorage(model, parsedSuggestions.suggestExternalStorage.identifierChain?.map(c => c.name))))
      .map((storage) => {
        return {
          label: storage.label,
          kind: kindOf('storage'),
          documentation: null, // "TODO",
          insertText: storage.label,
          range,
          detail: 'storage'
        }
      })))
  }

  // Suggest format.
  if (parsedSuggestions.suggestExternalFormats) {
    result.push(...nonEmpty('format', (await Promise.resolve(context.resourceProvider.getFormats(model, parsedSuggestions.suggestExternalFormats.identifierChain?.map(c => c.name))))
      .map((format) => {
        return {
          label: format.label,
          kind: kindOf('format'),
          documentation: null, // "TODO",
          insertText: format.label,
          range,
          detail: 'format'
        }
      })))
  }

  // Suggest aggregate functions.
  if (parsedSuggestions.suggestAggregateFunctions) {
    result.push(...aggregateFunctions.map((fn) => {
      return {
        label: fn,
        kind: kindOf('aggFunction'),
        documentation: null, // "TODO",
        insertText: fn,
        range,
        detail: 'aggregate function'
      }
    }))
  }

  // Handle the various suggestion types from the engine.
  false && (parsedSuggestions.locations || []).forEach(({ type, value }) => {
    switch (type) {
      case 'selectList':
        break
      case 'whereClause':
        break
      case 'limitClause':
        break
    }
  })

  // Do error processing.
  if (context?.resourceProvider?.errorHandler) {
    // Get the errors, minus partial value cursor (ie. editing within a quoted value, etc), and other weird errors.
    const errors = (parsedSuggestions.errors || [])
      .filter(error => syntax || !(error.token === 'PARTIAL_VALUE' && error.text === PARTIAL_CURSOR))
      .filter(error => syntax || error.expected?.length !== 2 || (error.expected[0] !== `':'` && error.expected[1] !== `'EOF'`))

    // Does one of the tokens just match a keyword offered,?  If so, ignore the batch of errors.
    const keywordsOffered = new Set((parsedSuggestions.suggestKeywords || []).map(keyword => keyword.value))
    keywordsOffered.add('*')
    const errorTokensMatchKeywordsOffered = !syntax && errors.reduce((result, error) => {
      return result && (keywordsOffered.has(error.token) || error.token === context.currentWord)
    }, true) || keywordsOffered.has(context.currentWord)
    await Promise.resolve(context.resourceProvider.errorHandler(model, !errorTokensMatchKeywordsOffered ? errors : []))
  }

  return result.length === 0 ? null : result
}

export default { provideCompletionItems }
