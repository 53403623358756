<template>
  <yb-editor
    v-model="editValue"
    v-resize
    v-document-class-watcher
    :language="language"
    :options="options"
    :theme="theme"
    @editor-will-mount="editorWillMount"
    @editor-did-mount="editorDidMount"
    @resize="resize"
    @document-class-change="documentClassChanged"
  />
</template>

<script>
import Dropzone from 'dropzone'
import { get } from 'vuex-pathify'
import YbEditor from './YbEditor.vue'

const FONT_SIZES = {
  xs: {
    fontSize: 10,
    lineHeight: 14
  },
  sm: {
    fontSize: 12,
    lineHeight: 16
  },
  base: {
    fontSize: 14,
    lineHeight: 18
  },
  md: {
    fontSize: 16,
    lineHeight: 20
  },
  lg: {
    fontSize: 18,
    lineHeight: 24
  },
  xl: {
    fontSize: 24,
    lineHeight: 28
  }
}

export default {
  components: {
    YbEditor
  },
  props: {
    language: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    minimap: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    const size = this.$store.get('settings/editor@fontSize') || 'base'
    const minimap = !!this.minimap && this.$store.get('settings/editor@minimap') !== false
    const font = FONT_SIZES[size] || FONT_SIZES.base

    return {
      editValue: this.modelValue,
      options: {
        disableMonospaceOptimizations: true,
        scrollBeyondLastLine: false,
        fontSize: font.fontSize || 14,
        lineHeight: font.lineHeight || 18,
        fontFamily: 'Menlo,Monaco,ui-monospace,SFMono-Regular,Consolas,"Liberation Mono","Courier New",monospace', // See also tailwind.config.js
        lineNumbersMinChars: 4,
        quickSuggestions: true,
        acceptSuggestionOnCommitCharacter: true,
        suggestOnTriggerCharacters: true,
        fixedOverflowWidgets: true,
        tabSize: Number(this.$store.get('settings/editor@indentLevel') || 4),
        minimap: {
          enabled: minimap
        }
      }
    }
  },
  computed: {
    settings: get('settings/editor', false),
    theme() {
      return this.$store.state.settings.theme === 'dark' ? 'vs-dark' : 'vs-code'
    }
  },
  watch: {
    modelValue(_) {
      this.editValue = _
    },
    editValue(_) {
      this.$emit('update:modelValue', _)
    },
    settings: {
      deep: true,
      handler(_) {
        // settings changed, lets update the editor.
        const size = _.fontSize
        const theme = this.theme
        this.options.fontSize = (FONT_SIZES[size] || FONT_SIZES.base).fontSize || 14
        this.options.minimap.enabled = _.minimap !== false
        this.options.tabSize = Number(_.indentLevel) || 4
        this.options.theme = theme
        this.editorInstance.updateOptions(this.options)
        this.monaco.editor.setTheme(theme)
      }
    }
  },
  beforeUnmount() {
    this.dropzone.destroy()
  },
  methods: {
    editorWillMount(monaco) {
      this.monaco = monaco
      this.$emit('editorWillMount', monaco)
    },
    editorDidMount(editor) {
      this.editorInstance = editor
      editor.focus()

      // Setup custom keybindings.

      this.hasSelection = editor.createContextKey('hasSelection', false)
      const updateSelection = (event) => {
        const {
          startColumn,
          startLineNumber,
          endColumn,
          endLineNumber
        } = editor.getSelection()
        const selection = startColumn !== endColumn || startLineNumber !== endLineNumber
        this.hasSelection.set(selection)
        this.$emit('selected', selection)
      }
      editor.onDidChangeCursorPosition(updateSelection)
      editor.onDidChangeCursorSelection(updateSelection)

      // Setup drop zone.
      this.setupDropzone()

      this.$emit('editorDidMount', editor)
    },

    setupDropzone() {
      this.dropzone = new Dropzone(this.$el, {
        url: '#',
        autoQueue: false,
        autoDiscover: false,
        maxFiles: 1,
        previewsContainer: false
      })
      this.dropzone.on('addedfile', (file) => {
        const fileReader = new FileReader()
        fileReader.onload = () => {
          this.editor.settings.sql = fileReader.result
        }
        fileReader.readAsText(file)
      })
    },

    focus() {
      this.editorInstance && this.editorInstance.focus()
    },

    resize(e) {
      this.editorInstance && this.editorInstance.layout()
    },

    selected() {
      return this.editorInstance.getModel().getValueInRange(this.editorInstance.getSelection())
    },

    documentClassChanged() {
      const theme = this.theme
      this.options.theme = theme
      this.editorInstance.updateOptions(this.options)
      this.monaco.editor.setTheme(theme)
    }
  }
}
</script>

<style scoped>
/* TODO: this doesn't work, but we can hook this class on drag over to do somethign here to indicate drag is going on */
.dz-drag-hover {
  opacity: 50;
}
</style>
